import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { AnimatePresence, LayoutGroup } from 'framer-motion/dist/framer-motion';
import * as S from './vendor/home-components';

import { store } from '../mobx/store';
import { Flex } from './Flex';
import { getName } from '../utils/utils';
import { BidderItem } from './BidderItem';

interface TopBiddersListProps {
  title: String;
  bidders: any[];
}

const TopBiddersList = observer((props:TopBiddersListProps) => {
  const {title: _title, bidders: _bidders} = props;
  const bidders = _bidders || [];
  if (!bidders?.length) return null;
  const title = _title || 'Top Bidders';
  return (
    <React.Fragment>
      <S.HeroStatsLarge
        style={{
          fontSize: store.largeFontSize,
          // textAlign: 'center',
          margin: '20px 0px 20px 0px',
          alignSelf: 'flex-start',
        }}
      >
        {title}
      </S.HeroStatsLarge>
      <Flex
        style={{
          gap: store.isDesktop ? 30 : 20,
          flexWrap: 'wrap',
          // justifyContent: 'center',
          pointerEvents: 'all',
        }}
      >
        <LayoutGroup initial={false} key={`${title}-list`}>
          {
            bidders.map((b, idx) => {
              return (
                <BidderItem
                  layoutId={idx}
                  key={b.address}
                  href={`https://opensea.io/${b.address}`}
                  name={getName(b)}
                  img={b.account && b.account.profile_img_url}
                  addr={b.address}
                  amount={b.amount}
                  isWhale
                />
              );
            })
          }
        </LayoutGroup>
      </Flex>
    </React.Fragment>
  );
});

export default TopBiddersList;
