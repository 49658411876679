import React, { useState, useRef, useEffect } from 'react';
import * as S from './AccordionStyled';

const AccordionRow: React.FC<{
  id: number;
  title: string;
  description: string;
  expanded?: boolean;
  onClick: () => void;
}> = ({ id, title, description, onClick, expanded }) => {
  const [contentHeight, setContentHeight] = useState(0);
  const contentContainerRef = useRef(null);

  useEffect(() => {
    const update = () => setContentHeight(
      Math.ceil(contentContainerRef.current?.clientHeight || 0),
    );
    window.addEventListener('resize', update);
    update();
    return () => window.removeEventListener('resize', update);
  }, []);
  return (
    <S.AccordionItem key={id}>
      <S.AccordionItemHead onClick={onClick}>
        <S.AccordionItemTitle dangerouslySetInnerHTML={{ __html: title }} />
        <S.AccordionItemTrigger open={expanded} />
      </S.AccordionItemHead>
      <S.AccordionItemDescription open={expanded} contentHeight={contentHeight}>
        <div
          ref={contentContainerRef}
          dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, '<br />') }}
        />
      </S.AccordionItemDescription>
    </S.AccordionItem>
  );
};

export const Accordion: React.FC<{
  list: { title: string; description: string }[];
}> = ({ list }) => {
  const [expandedItemId, setExpandedItem] = useState(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [transition, setTransition] = useState(true);
  const contentContainerRef = useRef(null);
  useEffect(() => {
    const container = contentContainerRef.current;
    if (!container) {
      return null;
    }
    function update() {
      setContentHeight(
        Math.ceil(contentContainerRef.current?.clientHeight || 0),
      );
    }
    update();
    const resizeObserver = new ResizeObserver(() => update());
    resizeObserver.observe(container);
    return () => resizeObserver.unobserve(container);
  }, [expandedItemId]);
  useEffect(() => {
    setTransition(false);
    setTimeout(() => {
      setTransition(true);
    }, 600);
  }, [expandedItemId]);
  return (
    <>
      <S.AccordionList height={contentHeight} transition={transition}>
        <S.ContentContainer ref={contentContainerRef}>
          {list.map((l, i) => (
            <AccordionRow
              key={`faq${i}`}
              id={i}
              title={l.title}
              description={l.description}
              expanded={i === expandedItemId}
              onClick={() => setExpandedItem(prev => (prev === i ? null : i))
              }
            />
          ))}
        </S.ContentContainer>
      </S.AccordionList>
    </>
  );
};
