import { DISCORD_URL, IG_URL, OS_URL } from '../utils/constants';
import { Flex } from './Flex';

type SocialMediaButtonProps = {
  src: String,
  href: String,
  size: number,
  imgStyle: any,
};

export const SocialMediaButton = ({href, src, size, imgStyle}:SocialMediaButtonProps) => {
  return (
    <a
      className="simple-btn"
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {
        typeof src === 'string' ? (
          <img
            style={{
              height: size || 45,
              width: size || 45,
              objectFit: 'contain',
              ...imgStyle,
            }}
            src={src}
            alt=""
          />
        ) : src
      }
    </a>
  );
};
export const ETHButton = ({isBlue, size, top}) => {
  return (
    <img
      style={{
        height: size || 45,
        width: size || 45,
        objectFit: 'contain',
      }}
      src="/images/social-media/eth.svg"
      alt=""
    />
  );
};

export const TwitterButton = ({isBlue, size, top, href}) => {
  return (
    <SocialMediaButton
      size={size}
      src="/images/social-media/akar-icons_twitter-fill.svg"
    />
  );
};

export const WebsiteButton = ({isBlue, size, top, href}) => {
  return (
    <SocialMediaButton
      size={size}
      href={href}
      src="/images/social-media/www.png"
    />
  );
};

export const DiscordButton = ({isBlue, size, top, href}) => {
  return (
    <SocialMediaButton
      size={size}
      href={DISCORD_URL}
      src="/images/social-media/akar-icons_discord-fill.svg"
    />
  );
};

export const IGButton = ({isBlue, size, top}) => {
  return (
    <SocialMediaButton
      size={size}
      href={IG_URL}
      src="/images/social-media/akar-icons_instagram-fill.svg"
    />
  );
};


export const TGButton = ({isBlue, size, top}) => {
  return (
    <SocialMediaButton
      size={size}
      href=""
      src="/images/social-media/telegram.svg"
    />
  );
};

export const OpenSeaButton = ({isBlue, size, top, href}) => {
  return (
    <a
      className="simple-btn"
      href={OS_URL}
      target="_blank"
      rel="noreferrer"
      style={{
        height: size || 45,
        width: size || 45,
        alignSelf: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <img
        style={{
          height: size || 65,
          width: size || 65,
        }}
        src="/images/social-media/opensea.svg"
        alt=""
      />
    </a>
  );
};

export const SocialMediaButtonSet = () => {
  return (
    <Flex>
      <OpenSeaButton size={24} />
      <div style={{width: 25}} />
      <DiscordButton size={24} />
      <div style={{width: 25}} />
      <TwitterButton size={24} />
    </Flex>
  );
};
