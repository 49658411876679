import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { motion, usePresence } from 'framer-motion/dist/framer-motion';

import styled from 'styled-components';
import MinBlock from './MinBlock';
import { getAddressString } from '../utils/utils';
import { Flex, VFlex } from './Flex';
import { DiscordButton, ETHButton, OpenSeaButton, TwitterButton, WebsiteButton } from './SocialMediaButton';
import { BIDDER_ITEM_WIDTH } from '../utils/constants';

const Div = styled.div``;
const A = styled.a``;

export const BidderItem = observer((props:{
  img: String,
  name: String,
  info: String,
  href: String,
  addr: String,
  place: String,
  amount: String,
  isWhale: Boolean,
  noAnimation: Boolean,
  noLink: Boolean,
  social: {
    opensea: String,
    website: String,
    twitter: String,
    discord: String,
  },
  layoutId: Number,
}) => {
  const {layoutId, social, noLink, noAnimation, img, name, info, href, isWhale, addr, amount} = props;
  const [isPresent, safeToRemove] = usePresence();
  const animations = {
    layout: true,
    initial: 'out',
    style: {
      position: (isPresent || noAnimation) ? 'relative' : 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: isWhale ? BIDDER_ITEM_WIDTH : 50,
      gap: 6,
    },
    animate: isPresent ? 'in' : 'out',
    whileTap: 'tapped',
    variants: {
      in: { opacity: 1 },
      out: { opacity: 0, scale: 1},
      tapped: { opacity: 0.5, transition: { duration: 0.1 } },
      hover: { opacity: 0.6 },
    },
    whileHover: 'hover',
    onAnimationComplete: () => !isPresent && safeToRemove(),
    // transition: { type: 'spring', stiffness: 500, damping: 50, mass: 1 },
  };
  const imgClassName = isWhale ? 'border-gradient' : null;
  const imgStyle = {
    width: isWhale ? 120 : 50,
    height: isWhale ? 120 : 50,
    borderRadius: 120,
  };
  const Wrapper = noAnimation ? Div : motion.div;
  const OuterWrapper = noLink ? Div : A;
  return (
    <OuterWrapper
      className={!noLink && 'simple-btn'}
      href={href}
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: 'none',
      }}
      layout
      layoutId={layoutId}
    >
      <Wrapper {...animations}>
        {
          img ? (
            <img
              style={imgStyle}
              className={imgClassName}
              src={img}
              alt=""
            />
          ) : (
            <MinBlock
              style={{...imgStyle}}
              className={imgClassName}
              config={{
                seed: addr,
              }}
            />
          )
        }
        {
          isWhale && (
            <React.Fragment>
              <div
                style={{
                  fontSize: 15,
                  margin: '5px 0px -5px 0px',
                  maxWidth: 160,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {name || getAddressString(addr)}
              </div>
              {
                amount && (
                  <div style={{fontSize: 20}}>
                    <ETHButton size={18} />
                    {Math.round(parseFloat(amount) * 100) / 100}
                  </div>
                )
              }
              {
                info && (
                  <div style={{
                    fontSize: 12,
                    margin: '-8px 0px 0px 0px',
                  }}
                  >
                    {info}
                  </div>
                )
              }
            </React.Fragment>
          )
        }
        {
          social && (
            <Flex
              style={{gap: 12}}
            >
              {
                social.opensea && (
                  <OpenSeaButton size={19} href={social.opensea} />
                )
              }
              {
                social.discord && (
                  <DiscordButton size={19} href={social.discord} />
                )
              }
              {
                social.twitter && (
                  <TwitterButton size={19} href={social.twitter} />
                )
              }
              {
                social.website && (
                  <WebsiteButton size={19} href={social.website} />
                )
              }
            </Flex>
          )
        }
      </Wrapper>
    </OuterWrapper>
  );
});
