/* eslint-disable no-nested-ternary */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { OnlyMobile, Fluid, SmartFluid, OnlyDesktop, OnlyLargeDesktop, FluidUltraWide } from './utils';

export const defaultGap = css`
  ${SmartFluid('gap', 16, 24, 32)};
`;

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  > p, > ul {
    font-family: 'Inter', sans-serif;
  }
  ul, ol {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 2em 0;
  }
  ${props => css`
    ${props.width === 'wide'
    ? css`
          ${SmartFluid('width', 330, 1332, 1800)}
        `
    : props.width === 'ultraWide'
      ? css`
          ${OnlyDesktop} {
            margin-left: 13px;
            margin-left: 13px;
          }
          ${OnlyLargeDesktop} {
            width: ${FluidUltraWide(1414, 2000)};
          }
        `
      : SmartFluid('width', 300, 1280, 1400)}
    ${props.grid
    && css`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      ${defaultGap}
      ${OnlyMobile} {
        grid-template-columns: repeat(4, 1fr);
      }
    `}
    ${props.spaceTop === 'huge'
    ? SmartFluid('margin-top', 200, 300, 400)
    : props.spaceTop === 'large'
      ? SmartFluid('margin-top', 100, 200, 300)
      : props.spaceTop === 'medium'
        ? SmartFluid('margin-top', 75, 120, 180)
        : props.spaceTop === 'regular'
          ? SmartFluid('margin-top', 60, 90, 120)
          : '0'};
    ${props.spaceBottom === 'huge'
    ? SmartFluid('margin-bottom', 200, 300, 400)
    : props.spaceBottom === 'large'
      ? SmartFluid('margin-bottom', 100, 200, 300)
      : props.spaceBottom === 'medium'
        ? SmartFluid('margin-bottom', 75, 120, 180)
        : props.spaceBottom === 'regular'
          ? SmartFluid('margin-bottom', 60, 90, 120)
          : '0'};
  `}
`;
