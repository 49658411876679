import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { now } from 'mobx-utils';
import { getTimeLeftObj, getTimeLeftStr } from './utils/utils';
import { NETWORK_CONFIG } from './utils/constants';
import { store } from './mobx/store';
import AuctionPart from './AuctionPart';
import AuctionFinishedPart from './components/AuctionFinishedPart';
import RefundAuctionPart from './components/RefundAuctionPart';
import { isWinnersReady } from './components/results';
import { Flex, VFlex } from './components/Flex';
import RefundWaitlistPart from './components/RefundWaitlistPart';

interface MintPartProps {
}

const MintPart = observer((props:MintPartProps) => {
  return (
    <React.Fragment>
      {
        (() => {
          if (store.auctionState === -1) {
            return (
              <h5>
                Loading Auction...
              </h5>
            );
          }
          if (store.auctionState === 0) {
            return (
              <h5>
                We are processing the auction result. Stay tuned!
              </h5>
            );
          }
          let components = [];
          if (store.auctionState == 1) {
            components.push((
              <AuctionPart />
            ));
          }
          if (store.auctionState > 1) {
            components.push((
              <AuctionFinishedPart />
            ));
          }
          components.push((
            <RefundAuctionPart />
          ));
          components.push((
            <RefundWaitlistPart />
          ));
          return components;
        })()
      }
    </React.Fragment>
  );
});

export default MintPart;
