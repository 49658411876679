import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as S from './HeaderStyled';
import { Container } from '../container';

export const Header: React.FC<{}> = () => {
  const [scrollDirection, setScrollDirection] = useState('zero');
  useEffect(() => {
    let lastScrollTop = 0;
    function update() {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st === 0) {
        setScrollDirection('zero');
      } else if (st > lastScrollTop) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      lastScrollTop = st <= 0 ? 0 : st;
    }
    window.addEventListener('scroll', update);
    return () => window.removeEventListener('scroll', update);
  }, []);
  return (
    <S.Header scrollDirection={scrollDirection}>
      <Container width="wide">
        <Link to="/">
          <svg width="140" height="63" viewBox="0 0 140 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 52.4V47c0-1.2 1-1.5 2.1-1.4v-.3h-10v.3c1.2-.1 2.3.3 2.2 1.5v4.7c-.4 2-2.3 3.4-5 3.4-4.8 0-8.1-4.2-8.1-10.3 0-6.3 3.4-10.6 8.3-10.6 4 0 7.4 1.9 8.5 6h.3v-5.9C-6.2 24.2-8 63.2 20.2 55.7c2.1 5.6 8.4 9.3 16 6.1l-.1-.3c-6.7 1.4-11-3.7-11-9.1Zm19.4.2c-1.7 2.7-5.2 2.3-8 2.3v-7.6h3.1c1.8 0 3 1.5 3 3.2h.3v-7.7h-.4a2.9 2.9 0 0 1-2.9 3.1h-3V38h4c2.3 0 3.5 1.6 3.4 3.8h.3v-5.7c-1.8.7-5.8.5-7.8.5h-7.7v.4c1.2-.2 2.1.6 2.1 1.7v15.6c0 1.1-1 1.8-2 1.7v.4h16l1.2-6.5h-.3c-.4 1-.8 2-1.3 2.7Zm24.7-16h-7.8v.4c1.1-.2 2.4.4 2.3 1.6v10L49.2 36.2H49c.8 2.4.3 15.5.4 18.2 0 1.2-1.2 1.8-2.3 1.6v.4h10.1V56c-1 .2-2.3-.4-2.3-1.6v-10l14.5 12.4h.3c-.8-2.5-.3-15.4-.4-18.2 0-1.2 1.3-1.7 2.4-1.6v-.3H69Zm19.5 16c-1.6 2.7-5.1 2.3-8 2.3v-7.6h3.2c1.8 0 3 1.5 2.9 3.2h.3v-7.7h-.3a2.9 2.9 0 0 1-2.9 3.1h-3.1V38h4c2.4 0 3.5 1.6 3.4 3.8h.4v-5.7c-1.9.7-5.8.5-7.9.5H73v.4c1.1-.2 2.1.6 2.1 1.7v15.6c0 1.1-1 1.8-2.1 1.7v.4h16l1.1-6.5H90c-.3 1-.7 2-1.3 2.7Zm15.2-7.4-4.7-2.6c-1-.6-2.5-1.1-2.4-2.6.6-3.8 8-2.8 7.7 1h.2l.6-3.9v-.5h-2c-4.2-.7-10.1-.8-10.6 4.7-.4 2.6 1 4.8 3.4 6l4 2.2c2.3 1 4 3.1 2 5-3.3 2.6-9-.6-8.5-4.8h-.2c-.9 1.4-1.5 3.7-.9 5.3 4.2 3.4 14.4 2.3 14.5-4.4.2-2.5-1-4.4-3-5.4Zm13.3 10.3a2 2 0 0 1-.5-1.2V38.6c-.1-1 1-1.8 2-1.6v-.4h-9.8v.4c1.2-.1 2.2.5 2.2 1.7v15.6c0 1.2-1 1.9-2.2 1.8v.3h10v-.3c-.6 0-1.3-.2-1.7-.6Zm18.6-11.3-6.2-3.8c-1.4-.9-2.7-1.6-2.6-3 .3-4.5 9.8-3.7 9.3.8h.3l.5-4.3v-.3l.1-.4h-2.3a53 53 0 0 0-5-.3c-7.6-.4-9.9 8.7-3.6 12.2l5.5 3.5c5.4 3.3 3.4 9.3-2.7 9.3-5.8 0-9.8-5.5-6.5-11l-.3-.3c-4.2 5.6-1 13 7.5 13 10 0 14-10.5 6-15.4Z" fill="url(#a)" />
            <path d="M23 28.9C6.7 29.6 2.7 9.4 17.6 5.4l.2.3c-5.5 5.4-4 22.2 5.2 21.6 5 0 8.3-4.1 8.3-10.6 0-6-2.7-10.6-7.4-10.2-3.9.3-5.4 4-4.4 7.4h-.4c-1.7-3.4-.5-8.2 4.8-9 17.2-1.4 19.3 24.2-.9 24Zm32-4.2c-1.7 2.7-5.2 2.3-8 2.3V10.8c0-1.2 1-1.8 2.2-1.7v-.4h-9.9v.4c1-.1 2.1.5 2.1 1.7v15.7c0 1-1 1.8-2.1 1.7v.3h16.1l1.2-6.4H56c-.3.9-.7 1.8-1.2 2.6Zm51.2 3.7 1.1-6.4h-.3c-.6 2.7-2.7 5-5.7 5h-3.5v-7.6h3.1c1.8 0 3 1.4 3 3.2h.3v-7.7h-.4a2.9 2.9 0 0 1-2.9 3h-3v-7.8h4c2.3 0 3.5 1.6 3.4 3.8h.3V8.2c-1.8.7-5.8.4-7.8.5H83.4V9c1-.1 1.9.7 1.3 1.8l-4.2 9.6-4.2-9.6c-.5-1.1.4-2 1.4-1.8v-.3H57.2V9c1.2 0 2.2.6 2.2 1.8v15.6c0 1.1-1 1.8-2.2 1.7v.4h10V28c-1 .1-2.3-.6-2.2-1.7V10.7c0-2.6 4.1-2 4.9 0l8.9 18h.2c.3-1.3 1.1-3 1.6-4.2l6.6-13.7c.8-2.2 4.8-2.7 5 0v15.5c0 1.1-1 1.8-2.2 1.7v.4h16.1Zm30.4-28V0c-7.4-.7-11.1 9-14.8 13.7l-4-6c-.6-1 .6-2.2 1.6-2v-.3h-12v.3c1.5-.1 3 .8 3.8 2l6 9.4-7.8 10.8c-2 2.6-3.5 4.7-7 5v.4c3.4.7 6.7-.7 9.9-5.1l6.3-9 4.5 6.9c.6 1-.5 2.2-1.6 2v.3h12.2v-.3c-1.5.1-3-.8-3.8-2L123 16l7-10.2c1.5-2.2 3.5-5.3 6.4-5.3Z" fill="url(#b)" />
            <defs>
              <linearGradient id="a" x1="118.4" y1="63" x2="118.4" y2="32.8" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CC9300" />
                <stop offset="1" stopColor="#FFD363" />
              </linearGradient>
              <linearGradient id="b" x1="116.8" y1="33.5" x2="116.8" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CC9300" />
                <stop offset="1" stopColor="#FFD363" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
      </Container>
    </S.Header>
  );
};
