import styled from '@emotion/styled';
import { SmartFluid } from '../utils';

export const Header = styled.header`
    position: fixed;
    z-index: 500;
    top: 0;
    padding: 1em 0;
    width: 100%;
    filter: drop-shadow(0px 0px 16px #090B0A);
    pointer-events: none;
    > * {
        pointer-events: auto;
    }
    svg {
        display: block;
        position: relative;
        z-index: 20;
        width: auto;
        ${SmartFluid('height', 54, 63, 72)};
    }
    &:after {
        position: absolute;
        content: '';
        background: red;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.4s ease;
        background: linear-gradient(
        to bottom,
        hsla(150, 10%, 4%, 1) 0%,
        hsla(150, 10%, 4%, 0.951) 15.5%,
        hsla(150, 10%, 4%, 0.825) 29%,
        hsla(150, 10%, 4%, 0.648) 41.2%,
        hsla(150, 10%, 4%, 0.45) 52.9%,
        hsla(150, 10%, 4%, 0.259) 64.7%,
        hsla(150, 10%, 4%, 0.104) 77.5%,
        hsla(150, 10%, 4%, 0.013) 91.9%,
        hsla(150, 10%, 4%, 0) 100%
        );
        opacity: ${props => (props.scrollDirection !== 'zero' ? '0.5' : '0')};
    }
`;
