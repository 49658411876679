import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import * as S from './CoverScrollStyled';

gsap.registerPlugin(ScrollTrigger);

export const CoverScroll = (
  props: React.PropsWithChildren<{
}>,
) => {
  const ref = useRef(null);
  const media = useRef(null);
  const [coverLoaded, setCoverLoaded] = useState(false);


  useEffect(() => {
    let timeline = gsap.timeline({
      paused: true,
      scrollTrigger: {
        start: 'top top',
        end: 'bottom bottom',
        scrub: 0,
      },
    });
    timeline.to(media.current,
      {
        y: '-50vh',
        ease: 'linear',
      });
    return () => timeline.scrollTrigger && timeline.scrollTrigger.kill();
  }, []);
  return (
    <S.CoverScroll ref={ref}>
      <S.CoverMedia ref={media} coverLoaded>
        <img src="/assets/cover.webp" style={{width: '100%', height: '100%'}} alt="" />
      </S.CoverMedia>
    </S.CoverScroll>
  );
};
