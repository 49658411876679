import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { OnlyDesktop, OnlyMobile, SmartFluid } from '../utils';
import { Container } from '../container';

export const Footer = styled(Container)`
    position: relative;
    z-index: 500;
    bottom: 0;
    padding-bottom: 1em;
    display: flex;
    ${SmartFluid('gap', 12, 16, 24)};
    a:link,a:visited {
        opacity: 0.6;
        transition: opacity 0.2s ease;
    }
    a:hover {
        opacity: 1;
    }
    img {
        display: block;
        ${SmartFluid('height', 34, 36, 42)};
        width: auto;
    }
    ${OnlyMobile} {
        bottom: 0.8em;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        img {
            margin: auto;
        }
    }
    display: flex;
    align-items: center;
`;

export const Legal = styled.div`
    font-size: 11px;
    font-family: 'Inter', sans-serif;
    display: flex;
    gap: 1em;
    ${OnlyDesktop} {
        margin-left: auto;
    }
    ${OnlyMobile} {
        justify-content: center;
        flex-wrap: wrap;
    }
`;
