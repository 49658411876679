import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CoverScroll = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
`;

export const CoverMedia = styled.div`
    position: absolute;
    width: 100%;
    height: 150vh;
    top: 0;
    left: 0;
    will-change: transform;
    background: #12111f;
    img {
        object-fit: cover;
        object-position: 50% 50%;
        opacity: 0;
        transform: scale(1.1);
        transition: opacity 3s ease, transform 3s cubic-bezier(0.2,0.8,0.2,1);
    }
    ${props => props.coverLoaded && css`
        img {
            transform: scale(1);
            opacity: 0.5;
            will-change: opacity;
        }
    `};
`;
