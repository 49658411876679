/* eslint-disable no-nested-ternary */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { OnlyMobile } from '../utils';


export const Letter = styled.span`
  position: relative;
  filter: blur(4px);
  opacity: 0;
  transform-style: preserve-3d;
  transition: transform 1.2s ease, filter 1.2s linear, opacity 1.2s linear;
  will-change: transform, filter, opacity;
  ${props => css`
    ${props.special && css`
      font-family: 'Cinzel Decorative', 'Cinezel', serif;
    `}
    ${props.space
      && css`
        width: 0.25em;
      `}
  `}
  background: linear-gradient(to top, #CC9300 0%, #FFD363 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const Word = styled.span`
  display: flex;
`;

export const LetterEffect = styled.span`
  perspective: 50vw;
  display: flex;
  flex-wrap: wrap;
  line-height: inherit;
  
  justify-content: ${props => (props.align === 'center'
    ? 'center'
    : props.align === 'right'
      ? 'end'
      : 'start')};
  flex-shrink: 0;
  ${props => (props.inview
    ? css`
      ${Letter} {
        transform: translate(0, 0) !important;
        filter: blur(0px);
        opacity: 1;
      }
    ` : css`
      ${Letter} {
        transition-delay: 0s, 0s, 0s !important;
      }
    `)}
  ${props => props.alignMobile && css`
    ${OnlyMobile} {
      justify-content:
        ${props.alignMobile === 'center'
    ? 'center'
    : props.alignMobile === 'right'
      ? 'end'
      : 'start'};
    }
  `}
`;
