export const OnlyMobile = '@media (max-aspect-ratio:1000/1001)';
export const OnlyLargeMobile = '@media (max-aspect-ratio: 1/1) and (min-width: 360px)';
export const OnlyDesktop = '@media (min-aspect-ratio: 1/1)';
export const OnlySquare = '@media (min-aspect-ratio: 2/3) and (max-aspect-ratio: 1/1)';
export const OnlyTallMobile = '@media (max-aspect-ratio: 7/12) and (min-width: 360px)';
export const OnlyLargeDesktop = '@media (min-width: 1440px)';
export const OnlyTouch = '@media (pointer: coarse)';
export const OnlyMouse = '@media (pointer: fine)';

const displayUltraWide = 2560;
const displayWide = 1440;
const displayNarrow = 360;

export const Fluid = function (minValue: number, maxValue: number) {
  const x = (maxValue - minValue) / (displayWide - displayNarrow);
  const y = maxValue - displayWide * x;
  return (
    `calc(${
      Number(100 * x).toFixed(2)
    }vw + ${
      Number(y).toFixed(2)
    }px)`
  );
};

export const FluidUltraWide = function (minValue: number, maxValue: number) {
  const x = (maxValue - minValue) / (displayUltraWide - displayWide);
  const y = maxValue - displayUltraWide * x;
  return (
    `calc(${
      Number(100 * x).toFixed(2)
    }vw + ${
      Number(y).toFixed(2)
    }px)`
  );
};

export const SmartFluid = function (
  attribute: string,
  minValue: number,
  medValue: number,
  maxValue: number,
) {
  return (
    `${attribute
    }: ${
      Fluid(minValue, medValue)
    };`
    + `@media (min-width: 1441px) {${
      attribute
    }: ${
      FluidUltraWide(medValue, maxValue)
    }; }`
  );
};
