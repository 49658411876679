import React from 'react';
import * as S from './CtaStyled';

export const Cta = (
  props: React.ComponentProps<typeof S.Cta> & {
    as?: 'button' | 'a' | 'span';
    target?: string;
    rel?: string;
    mode?: 'primary' | 'secondary';
    danger?: boolean;
    size?: 'regular' | 'large';
  },
) => {
  return (
    <S.Cta as={props.as} {...props}>
      <S.HoverSpace />
      <S.InnerCta>
        {props.mode === 'secondary' && (
          <React.Fragment>
            <S.Corners />
            <S.Corners />
          </React.Fragment>
        )
        }
        {props.children}
      </S.InnerCta>
      {props.mode === 'secondary' && (
        <React.Fragment>
          <S.OuterDecor width="30" height="5" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5H0c.6-1 1.6-2 2.6-2.7C5.6 3.7 13.2 3 15 0c1.8 3 9.4 3.7 12.4 2.3 1 .7 2 1.8 2.6 2.7H15Z" fill="#CC9300" />
          </S.OuterDecor>
          <S.OuterDecor width="30" height="5" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5H0c.6-1 1.6-2 2.6-2.7C5.6 3.7 13.2 3 15 0c1.8 3 9.4 3.7 12.4 2.3 1 .7 2 1.8 2.6 2.7H15Z" fill="#CC9300" />
          </S.OuterDecor>
        </React.Fragment>
      )}
    </S.Cta>
  );
};
