import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useWeb3React } from '@web3-react/core';
import { store } from '../../mobx/store';

import * as S from '../vendor/home-components';
import { Container } from '../vendor/container';
import { LetterStoryEffect } from '../vendor/LetterStoryEffect/LetterStoryEffect';
import { Accordion } from '../vendor/Accordion/Accordion';
import { Cta } from '../vendor/Cta/Cta';
import { useConnect } from '../../hooks/hooks';
import RecentBiddersList from '../RecentBiddersList';
import TopBiddersList from '../TopBiddersList';
import MintPart from '../../MintPart';
import { CoverScroll } from '../vendor/CoverScroll/CoverScroll';

interface HomePageProps {
}

const HomePage = observer((props: HomePageProps) => {
  const [hasClaimed, setHasClaimed] = useState(false); // CLICKED, TX_SENT, TX_CONFIRMED
  const ctx = useWeb3React();
  const connect = useConnect();
  useEffect(() => {
    (async () => {
      if (store.myWhitelist && store.mintContract) {
        setHasClaimed(await store.mintContract.hasClaimed(ctx.account));
      }
    })();
  }, [store.myWhitelist, store.mintContract, ctx.account]);
  const usersWillReceiveAirdrop = parseInt(store.usersWillReceiveAirdrop, 10);
  const usersCountEnteredWaitlist = parseInt(store.usersCountEnteredWaitlist, 10);
  return (
    <S.HomePage>
      <Container spaceTop="medium" spaceBottom="small" grid>
        <S.HeroContainer>
          <S.Hero>
            <img src="/assets/hero/mystery-box.gif" alt="" />
          </S.Hero>
          {
            store.web3Ctx?.account ? (
              <MintPart />
            ) : (
              <Cta
                mode="secondary"
                size="large"
                onClick={connect}
              >
                Connect Wallet
              </Cta>
            )
          }
        </S.HeroContainer>
        <S.HeroStats>
          <div>
            <h3>Genesis Auction</h3>
            <S.HeroStatsLarge numbers faded={store.auctionDateText === 'Ended'}>{store.auctionDateText}</S.HeroStatsLarge>
          </div>
          <div>
            <h3>Mint Period</h3>
            <S.HeroStatsLarge numbers faded={store.mintDateText === 'Ended'}>{store.mintDateText}</S.HeroStatsLarge>
          </div>
          <div>
            <h3>Airdrop</h3>
            <S.HeroStatsLarge numbers faded={store.airdropDateText === 'Ended'}>{store.airdropDateText}</S.HeroStatsLarge>
          </div>
        </S.HeroStats>
      </Container>
      <Container spaceBottom="regular">
        <RecentBiddersList />
        <TopBiddersList
          bidders={(store.stats && store.stats.topBidders && store.stats.topBidders)}
        />
        <TopBiddersList
          title="OG"
          bidders={store.stats && store.stats.whales}
        />
      </Container>
      {
        parseInt(store.auctionState, 10) > 1 && (
          <Container spaceBottom="huge">
            <S.Stats>
              <div>
                <S.HeroStatsLarge numbers>{usersWillReceiveAirdrop}</S.HeroStatsLarge>
                <h3>Genesis Auction</h3>
              </div>
              <div>
                <S.HeroStatsLarge numbers>{usersCountEnteredWaitlist}</S.HeroStatsLarge>
                <h3>Reserved</h3>
              </div>
              <div>
                <S.HeroStatsLarge numbers>{store.waitlistVacancy}</S.HeroStatsLarge>
                <h3>Vacancy</h3>
              </div>
              <div>
                <S.HeroStatsLarge numbers>{store.stats?.participants || 0}</S.HeroStatsLarge>
                <h3>Bidders</h3>
              </div>
              <div>
                <S.HeroStatsLarge numbers>751</S.HeroStatsLarge>
                <h3>ETH Locked</h3>
              </div>
            </S.Stats>
          </Container>
        )
      }
      <Container spaceBottom="huge">
        <h2 style={{ textAlign: 'center' }}>
          <p>
            When humans first walked, there was nothing like money…
            <br />
            if you wanted some salt, you’d have to trade honey.
            <br />
            Plant the right seeds and your grapevine would grow,
            <br />
            more wine for your cup, more faces to know.
          </p>
          <p>
            So here is my offer to you, mortal kind,
            <br />
            Bid for a box to be by my side.
            <br />
            In the light of my Titans, you’ll finally see,
            <br />
            Just what it feels like to wield power like me.
          </p>
        </h2>
      </Container>
      <Container spaceTop="huge" spaceBottom="huge" grid>
        <S.CenterColumn>
          <h1><LetterStoryEffect text="OliveX & GenesiS" align="center" /></h1>
        </S.CenterColumn>
        <S.LeftDescriptionColumn>
          <S.ParagraphLarge>
            OliveX has established itself as a major builder and innovator in the Web3 space and leverages the power of storytelling to offer immersive fitness gaming experiences to communities around the world.
            The creative concept of Genesis is no exception.
          </S.ParagraphLarge>
        </S.LeftDescriptionColumn>
        <S.DescriptionColumn>
          <S.P>The OliveX Genesis collection is the most exclusive collection in the OliveX ecosystem, offering holders access to a best in class Web3 network that draws from an abundance of leading blockchain-based businesses and founders in gaming, wellness and fitness, the move-and-earn industry, and many more. </S.P>
          <S.P>Genesis holders will be plugged-in to this network, with access to individuals within the OliveX product suite, its investment portfolio and rich network of advisors.</S.P>
        </S.DescriptionColumn>
      </Container>
      <Container spaceTop="huge" spaceBottom="regular" grid>
        <S.CenterColumn>
          <h1>
            <LetterStoryEffect text="Treasures of The Onifox" align="center" />
          </h1>
          <h2><LetterStoryEffect text={'(’EN-EFF-TEE’)'.toLocaleLowerCase()} align="center" delay={1} /></h2>
          <img src="/assets/story/fox.webp" alt="" />
        </S.CenterColumn>
      </Container>
      <S.OverflowContainer>
        <S.FoxLogos grid>
          <img src="/assets/logos/01.webp" alt="" />
          <img src="/assets/logos/02.webp" alt="" />
          <img src="/assets/logos/03.webp" alt="" />
          <img src="/assets/logos/04.webp" alt="" />
          <img src="/assets/logos/05.webp" alt="" />
          <img src="/assets/logos/06.webp" alt="" />
          <img src="/assets/logos/07.webp" alt="" />
          <img src="/assets/logos/08.webp" alt="" />
          <img src="/assets/logos/09.webp" alt="" />
          <img src="/assets/logos/10.webp" alt="" />
          <img src="/assets/logos/11.webp" alt="" />
          <img src="/assets/logos/12.webp" alt="" />
          <img src="/assets/logos/13.webp" alt="" />
          <img src="/assets/logos/14.webp" alt="" />
          <img src="/assets/logos/15.webp" alt="" />
        </S.FoxLogos>
      </S.OverflowContainer>
      <Container spaceBottom="huge" grid>
        <S.LeftDescriptionColumn>
          <S.ParagraphLarge>
            The Onifox is a wild shapeshifter but has been known to side with powerful entities over the millennia.
            Loyal, cunning and mischievous, these shapeshifters were conjured to manifest wishful outcomes and treasures for those deemed worthy.
          </S.ParagraphLarge>
        </S.LeftDescriptionColumn>
        <S.DescriptionColumn>
          <S.P>Holders of OliveX Genesis will receive airdrops and whitelist opportunities from these mysterious creatures. The extended network of OliveX will also bring different project collaboration opportunities.</S.P>
          <S.P>A few of the upcoming projects include:</S.P>
          <ul>
            <li>Mocaverse 40 airdrop</li>
            <li>Binary 370 whitelists</li>
            <li>YogaPetz (Resortlist) 370 whitelists</li>
          </ul>
        </S.DescriptionColumn>
      </Container>
      <Container spaceTop="huge" spaceBottom="huge" grid>
        <S.CenterColumn>
          <h1>
            <LetterStoryEffect text="Light of The Titans" align="center" />
          </h1>
          <h2><LetterStoryEffect text={'(‘KON-SUHL-TAY-SHUHN’)'.toLowerCase()} align="center" delay={1} /></h2>
        </S.CenterColumn>
        <S.Advisors>
          <div>
            <picture>
              <img src="/assets/advisors/rumjahn.webp" alt="" />
            </picture>
            @rumjahn
          </div>
          <div>
            <picture>
              <img src="/assets/advisors/ysiu.webp" alt="" />
            </picture>
            @ysiu
          </div>
          <div>
            <picture>
              <img src="/assets/advisors/keung.webp" alt="" />
            </picture>
            @keung
          </div>
          <div>
            <picture>
              <img src="/assets/advisors/9gagceo.webp" alt="" />
            </picture>
            @9gagceo
          </div>
          <div>
            <picture>
              <img src="/assets/advisors/alexkongz.webp" alt="" />
            </picture>
            @alexkongz
          </div>
          <div>
            <picture>
              <img src="/assets/advisors/huuep.webp" alt="" />
            </picture>
            @huuep
          </div>
          <div>
            <picture>
              <img src="/assets/advisors/andrewchoi5.webp" alt="" />
            </picture>
            @andrewchoi5
          </div>
          <div>
            <picture>
              <img src="/assets/advisors/mrgentleape.webp" alt="" />
            </picture>
            @mrgentleape
          </div>
          <div>
            <picture>
              <img src="/assets/advisors/heygentlewhale.webp" alt="" />
            </picture>
            @heygentlewhale
          </div>
          <div>
            <picture>
              <img src="/assets/advisors/meta__mane.webp" alt="" />
            </picture>
            @meta__mane
          </div>
        </S.Advisors>
        <S.LeftDescriptionColumn>
          <S.ParagraphLarge>
            May those who seek it find the light of the Titans;
            <br />
            only the worthiest of mortals may ascend, enlightened.
          </S.ParagraphLarge>
        </S.LeftDescriptionColumn>
        <S.DescriptionColumn>
          <S.P>A place where Web3 ideas are born and incubated.</S.P>
          <S.P>OliveX Genesis is a project supported by the Titan Advisor network. Aspiring founders and builders can consult and receive guidance from OliveX CEO and co-founder @rumjahn on all things Web3 - from business modelling, NFT and product strategy, community building, marketing and fundraising.</S.P>
        </S.DescriptionColumn>
      </Container>
      <Container spaceTop="huge" spaceBottom="huge" grid>
        <S.CenterColumn>
          <h1>
            <LetterStoryEffect text="Temple of the OliGods" align="center" />
          </h1>
          <h2><LetterStoryEffect text={'(’KA-MYOO-NA-TI’)'.toLowerCase()} align="center" delay={1} /></h2>
          <img src="/assets/story/books.webp" alt="" />
        </S.CenterColumn>
        <S.LeftDescriptionColumn>
          <S.ParagraphLarge>
            The inner sanctum of my realm, where Titans and immortals convene.
            <br />
            Share the wisdom of ages, and prophecies unseen.
          </S.ParagraphLarge>
        </S.LeftDescriptionColumn>
        <S.DescriptionColumn>
          <S.P>A space where the future of Web3 is shaped for the better. Gain exclusive access to a close-knit community catered to both curious souls and web3 enthusiasts alike.</S.P>
        </S.DescriptionColumn>
      </Container>
      <Container spaceTop="huge" spaceBottom="huge" grid>
        <S.CenterColumn>
          <h1>
            <LetterStoryEffect text="The Gods and The Terra" align="center" />
          </h1>
          <h2><LetterStoryEffect text={'(’GEI-MI-FAI’)'.toLowerCase()} align="center" delay={1} /></h2>
          <img src="/assets/story/guard.webp" alt="" />
        </S.CenterColumn>
        <S.LeftDescriptionColumn>
          <S.ParagraphLarge>
            The powers of old, the gods and the monsters, have all been replaced, their great strength has been squandered.
          </S.ParagraphLarge>
          <S.ParagraphLarge>
            You built your own gods, temples and demons…and immediately found an excuse to unleash them.
          </S.ParagraphLarge>
          <S.ParagraphLarge>
            I strongly suspect you no longer need me, can I, I wonder, at long last be free?
          </S.ParagraphLarge>
        </S.LeftDescriptionColumn>
        <S.DescriptionColumn>
          <S.P>Coming soon - OliveX’s most potent weapon - building immersive, storytelling gaming worlds that pique the imagination. Our Genesis collection is no exception and will utilise our strengths in world building and production to deliver an episodic immersive experience with decisions, consequences and rewards.</S.P>
          <S.P>The Genesis collection is a mixture of Gods and Terra, and that will be where our story begins.</S.P>
          <video
            autoPlay
            loop
            muted
            playsInline
            poster="/assets/wreath/save.webp"
          >
            <source src="/assets/wreath/save.mov" type="video/quicktime" />
            <source src="/assets/wreath/save.webm" type="video/webm" />
          </video>
        </S.DescriptionColumn>
      </Container>
      <Container spaceTop="huge" spaceBottom="huge" grid>
        <S.CenterColumn>
          <h1>
            <LetterStoryEffect text="Heroes Journey" align="center" />
          </h1>
          <h2><LetterStoryEffect text={'(’EV-UH-GREEN’)'.toLowerCase()} align="center" delay={1} /></h2>
          <img src="/assets/story/temple.webp" alt="" />
        </S.CenterColumn>
        <S.LeftDescriptionColumn>
          <S.ParagraphLarge>
            Enter this realm with an open mind and you may find rewards beyond worldly pleasures.
            Every Soul has the chance to write their own story here...to climb from the clutches of Limbo, to navigate The Labyrinth and be welcomed into The OliGod’s Temple...or merely fade away as a faint whisper in The Song of Destiny.
          </S.ParagraphLarge>
        </S.LeftDescriptionColumn>
        <S.DescriptionColumn>
          <S.P>We are dedicated to creating evergreen experiences for our engaged communities, even those unable to mint, with the ability to work their way up to enjoy temporary and tiered benefits from the OliveX ecosystem.</S.P>
        </S.DescriptionColumn>
      </Container>
      <Container spaceTop="huge" spaceBottom="huge" grid>
        <S.CenterColumn>
          <h1><LetterStoryEffect text="FAQ" /></h1>
          <Accordion list={[
            { title: 'What is OliveX Genesis?', description: '• TLDR: An NFT that gives you access to the shared network and business experience of top Web3 builders and investors that OliveX holds.\n\n• OliveX Genesis is the most exclusive collection under the OliveX ecosystem. It is a first class Web3 network that draws on knowledge and perks from an abundance of top notch blockchain-based businesses and founders - across gaming, wellness and fitness, move-and-earn, as well as builders, investors, our wider titan and advisors.' },
            { title: 'How can I join the auction?', description: 'Place a bid during the auction period.' },
            { title: 'What is the auction period and logistics?', description: 'Blind auction duration: 72 hours\n• Starting bid (deposit): 0.333 ETH\n• Winners: Top 370 bids\n• Final floor price: The 370th highest bid\n• Your final bidding price is inclusive of the deposit\n• You can raise your bid anytime during the auction period having paid the 0.333 ETH deposit once\n\n\nMinting period: 48 hours\n• Top 370 bids get to mint the Genesis at final floor price\n• Winners have to pay for the difference between the 0.333 ETH deposit and final floor price\n• If you do not win, your bid(s) will be fully refunded\n• If you won the bid but decide not to mint at the end, you will have to forfeit the 0.333 ETH deposit paid\n• Airdrop: Within 24 hours post mint' },
            { title: 'What is the mint price?', description: 'Starting bid (also as deposit): 0.333 ETH' },
            { title: 'What is the supply of OliveX Genesis? ', description: 'Total supply: 420\nPublic auction: 370\nTreasury: 50' },
            { title: 'Is there any whitelist mint?', description: 'No' },
            { title: 'Is there any waitlist mint?', description: '• Anyone who didn’t win the auction or didn’t place a bid during the auction period can join the waitlist during the 48 hours minting window for a chance to mint\n• Any unminted spots from the winning bidders will then go to waitlist buyers on a FCFS basis' },
            { title: 'Where to find more information and ask questions?', description: '• Join the OliveX Genesis discord\n• Follow us on <a href="https://twitter.com/olivexai" target="_blank" title="Follow us on Twitter" rel="noopener noreferrer nofollow">@olivexai</a> official Twitter\n\n\nWe will NEVER message you first and ask for your PERSONAL INFORMATION.\nDON’T TRUST. VERIFY.' },
          ]}
          />
        </S.CenterColumn>
      </Container>
      <CoverScroll />
    </S.HomePage>
  );
});

export default HomePage;
