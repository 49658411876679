import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect } from 'react';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { ConnectorUpdate } from '@web3-react/types';

import { InjectedConnector } from '@web3-react/injected-connector';
import { NETWORK_CONFIG, NETWORK_ID } from '../utils/constants';
import { networkSetup } from '../utils/utils';
import { store } from '../mobx/store';

class RequestError extends Error {
  constructor(message: string, code: number, data?: any) {
    super(message);
  }
}


class MiniRpcProvider {
  isMetaMask: false = false

  chainId: number

  url: string

  host: string

  path: string

  batchWaitTimeMs: number

  nextId = 1

  batchTimeoutId = null

  batch = []

  constructor(chainId: number, url: string, batchWaitTimeMs?: number) {
    this.chainId = chainId;
    this.url = url;
    const parsed = new URL(url);
    this.host = parsed.host;
    this.path = parsed.pathname;
    // how long to wait to batch calls
    this.batchWaitTimeMs = batchWaitTimeMs ?? 50;
  }

  clearBatch = async () => {
    console.info('Clearing batch', this.batch);
    const { batch } = this;
    this.batch = [];
    this.batchTimeoutId = null;
    let response;
    try {
      response = await fetch(this.url, {
        method: 'POST',
        headers: { 'content-type': 'application/json', accept: 'application/json' },
        body: JSON.stringify(batch.map(item => item.request)),
      });
    } catch (error) {
      batch.forEach(({ reject }) => reject(new Error('Failed to send batch call')));
      return;
    }

    if (!response.ok) {
      batch.forEach(({ reject }) => reject(new Error(`${response.status}: ${response.statusText}`, -32000)));
      return;
    }

    let json;
    try {
      json = await response.json();
    } catch (error) {
      batch.forEach(({ reject }) => reject(new Error('Failed to parse JSON response')));
      return;
    }
    const byKey = batch.reduce((memo, current) => {
      memo[current.request.id] = current;
      return memo;
    }, {});
    // eslint-disable-next-line no-restricted-syntax
    for (const result of json) {
      const {
        resolve,
        reject,
        request: { method },
      } = byKey[result.id];
      if (resolve && reject) {
        if ('error' in result) {
          reject(new Error(result?.error?.message, result?.error?.code, result?.error?.data));
        } else if ('result' in result) {
          resolve(result.result);
        } else {
          reject(new RequestError(`Received unexpected JSON-RPC response to ${method} request.`, -32000, result));
        }
      }
    }
  }

  sendAsync = (
    request: { jsonrpc: '2.0'; id: number | string | null; method: string; params?: any },
    callback: (error: any, response: any) => void,
  ): void => {
    this.request(request.method, request.params)
      .then(result => callback(null, { jsonrpc: '2.0', id: request.id, result }))
      .catch(error => callback(error, null));
  }

  request = async (
    method: string | { method: string; params: any[] },
    params?: any,
  ): Promise<any> => {
    if (typeof method !== 'string') {
      return this.request(method.method, method.params);
    }
    if (method === 'eth_chainId') {
      return `0x${this.chainId.toString(16)}`;
    }
    const promise = new Promise((resolve, reject) => {
      this.batch.push({
        request: {
          jsonrpc: '2.0',
          id: this.nextId++,
          method,
          params,
        },
        resolve,
        reject,
      });
    });
    this.batchTimeoutId = this.batchTimeoutId ?? setTimeout(this.clearBatch, this.batchWaitTimeMs);
    return promise;
  }
}

export class NetworkConnector extends AbstractConnector {
  providers: { [chainId: number]: MiniRpcProvider }

  currentChainId: number

  constructor({ urls, defaultChainId }) {
    // invariant(defaultChainId || Object.keys(urls).length === 1, 'defaultChainId is a required argument with >1 url')
    super({ supportedChainIds: Object.keys(urls).map((k): number => Number(k)) });

    this.currentChainId = defaultChainId || Number(Object.keys(urls)[0]);
    this.providers = Object.keys(urls).reduce((accumulator, chainId) => {
      accumulator[Number(chainId)] = new MiniRpcProvider(Number(chainId), urls[Number(chainId)]);
      return accumulator;
    }, {});
  }

  get provider(): MiniRpcProvider {
    return this.providers[this.currentChainId];
  }

  async activate(): Promise<ConnectorUpdate> {
    return { provider: this.providers[this.currentChainId], chainId: this.currentChainId, account: null };
  }

  async getProvider(): Promise<MiniRpcProvider> {
    return this.providers[this.currentChainId];
  }

  async getChainId(): Promise<number> {
    return this.currentChainId;
  }

  async getAccount(): Promise<null> {
    return null;
  }

  deactivate() {
    return null;
  }
}


export const networkConnector = new NetworkConnector({
  urls: { [NETWORK_ID]: NETWORK_CONFIG.rpc },
});

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [parseInt(NETWORK_ID, 10)],
});

const Web3ContractETH = require('web3-eth-contract');

Web3ContractETH.setProvider(new MiniRpcProvider(NETWORK_CONFIG.chainId, NETWORK_CONFIG.rpc));

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React(); // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // console.log('handleChainChanged');
        // eat errors
        activate(injectedConnector, undefined, true).catch((e) => {
          console.error('Failed to activate after chain changed', e);
        });
      };

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injectedConnector, undefined, true).catch((e) => {
            console.error('Failed to activate after accounts changed', e);
          });
        }
      };

      const handleDisconnect = (code: number, reason: string) => {
        // console.log('handleDisconnect');
        // console.log(code, reason);
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('disconnect', handleDisconnect);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
    return undefined;
  }, [active, error, suppress, activate]);
}

export const useConnect = (setupNetwork) => {
  const ctx = useWeb3React();
  return useCallback(() => {
    ctx.activate(injectedConnector, (error) => {
      console.log(error.message);
      if (error.message.includes('Unsupported chain id')) {
        store.isIncorrectNetwork = true;
        if (setupNetwork) {
          networkSetup();
        }
      }
    });
  }, [ctx]);
};
