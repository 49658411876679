import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { reaction } from 'mobx';
import { CSSTransition } from 'react-transition-group';
import Providers from './components/Providers';
import GlobalCSS from './GlobalStyle';
import { store } from './mobx/store';
import HomePage from './components/pages/HomePage';
import { tryForever } from './utils/utils';
import { CoverScroll } from './components/vendor/CoverScroll/CoverScroll';
import { Header } from './components/vendor/header/Header';
import { Footer } from './components/vendor/footer/Footer';
import TermOfUsePage from './components/pages/TermOfUsePage';
import PrivacyPolicyPagePage from './components/pages/PrivacyPolicyPage';
import ScrollToTop from './components/ScrollToTop';

const routes = [
  { path: '/', Component: HomePage, exact: true, strict: true },
  { path: '/terms', Component: TermOfUsePage, exact: true, strict: true },
  { path: '/privacy', Component: PrivacyPolicyPagePage, exact: true, strict: true },
];

const Main = () => {
  const ctx = useWeb3React();

  useEffect(() => {
    store.web3Ctx = ctx;
  }, [ctx, ctx.account]);

  useEffect(() => {
    store.fetchInfos();
    tryForever(() => {
      return store.fetchStats();
    }, 'fetch stats');
    store.fetchVacancy();
    store.fetchUsersCountEnteredWaitlist();
    store.fetchUsersWillReceiveAirdrop();
    store.fetchSpace();
    const listener = () => {
      requestAnimationFrame(() => {
        store.setScrollYOffset(window.pageYOffset);
      });
    };
    window.addEventListener('scroll', listener);
    const resizeListener = () => {
      requestAnimationFrame(() => {
        store.setScreenSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      });
    };
    window.addEventListener('resize', resizeListener);
    const interval = setInterval(async () => {
      try {
        await store.fetchStats();
      } catch (e) {
      }
    }, 30000);
    const interval10 = setInterval(() => {
      store.now = Date.now();
    }, 1000);
    return () => {
      window.removeEventListener('scroll', listener);
      window.removeEventListener('resize', resizeListener);
      clearInterval(interval);
      clearInterval(interval10);
    };
  }, []);

  const prevAcc = useRef();
  useEffect(() => {
    if (prevAcc.current && ctx.account && (prevAcc.current !== ctx.account)) {
      // store.logout();
      store.contractFetchedForUser = false;
      store.addingMoreProteges = false;
      store.fetchInfos();
    } else {
      store.fetchInfos();
    }
    if (
      !prevAcc.current && ctx.account && !store.hasValidSignature()
    ) {
      store.signInDialog = true;
    }
    prevAcc.current = ctx.account;
    // if (!ctx.account && location.pathname.length !== 1) {
    //   document.location.href = '/';
    // }
  }, [ctx.account, location.pathname]);

  useEffect(() => {
    const fn = async () => {
      if (store.ownBid === undefined && store.hasValidSignature()) {
        tryForever(async () => {
          await store.fetchOwnBid();
        }, 'fetchOwnBid');
      }
    };
    fn();
    return reaction(() => store.userSignature, fn);
  }, [ctx, ctx.account]);
  return routes.map(({ path, Component, ...p }) => {
    return (
      <Route key={path} path={path} {...p} component={Component} />
    );
  });
};

const App = () => {
  return (
    <Providers>
      <GlobalCSS />
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Main />
        <Footer />
      </BrowserRouter>
    </Providers>
  );
};

export default App;
