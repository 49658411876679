import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Container } from './container';
import { OnlyDesktop, OnlyMobile, SmartFluid } from './utils';

export const HomePage = styled.div`
    position: relative;
    z-index: 50;
    h1, h2 {
        filter: drop-shadow(0px 0px 24px #090B0A);
    }
`;
export const OverflowContainer = styled.div`
    ${OnlyMobile} {
        overflow: auto;
        padding: 0 2em 2em 1em;
    }
`;
export const FoxLogos = styled(Container)`
    grid-template-columns: repeat(5, 1fr);
    img {
        width: 100%;
        height: auto;
        aspect-ratio: 2 / 1;
    }
    ${OnlyMobile} {
        width: 200vw !important;
        grid-template-columns: repeat(5, 1fr);
    }
`;
export const StickyColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: 1 / 5;
    gap: 2em;
    ${OnlyDesktop} {
        position: sticky;
        align-self: start;
        top: 50vh;
        transform: translateY(-50%);
    }
`;
export const CenterColumn = styled.div`
    grid-column: 1 / 13;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    img {
        width: 100%;
        height: auto;
        margin: 2em auto 0 auto;
    }
    ${OnlyMobile} {
        grid-column: 1 / 5;
        img {
            margin: 1em auto 0 auto;
        }
    }
`;
export const Advisors = styled.div`
    grid-column: 1 / 13;
    display: grid;
    padding-top: 4em;
    grid-template-columns: repeat(5, 1fr);
    ${SmartFluid('gap', 30, 60, 90)}
    ${SmartFluid('font-size', 12, 15, 16)}
    ${OnlyMobile} {
        grid-column: 1 / 5;
        grid-template-columns: repeat(2, 1fr);
        padding: 0 2em;
    }
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
    }
    img {
        max-width: 100%;
        display: block;
    }
    picture {
        display: block;
        overflow: hidden;
        border-radius: 50%;
    }
`;
export const LeftDescriptionColumn = styled.div`
    grid-column: 1 / 7;
    ${SmartFluid('padding-top', 30, 30 * 2, 36 * 2)};
    p {
        margin: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 1em;
    ${OnlyMobile} {
        grid-column: 1 / 5;
    }
`;
export const DescriptionColumn = styled.div`
    grid-column: 8 / 13;
    ${SmartFluid('padding-top', 22 * 2, 30 * 2, 36 * 2)};
    ul {
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-weight: 300;
    }
    display: flex;
    flex-direction: column;
    gap: 1em;
    ${OnlyMobile} {
        grid-column: 1 / 5;
    }
    video {
        width: 100%;
        transform: translateY(-10%);
        pointer-events: none;
    }
`;
export const P = styled.p`
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
`;
export const ParagraphLarge = styled.p`
    ${SmartFluid('font-size', 20, 30, 36)};
    line-height: 1.5em;
    filter: drop-shadow(0px 0px 24px #090B0A);
`;
export const HeroContainer = styled.div`
    grid-column: 1 / 7;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    ${OnlyMobile} {
        padding-top: 4em;
    }
`;
export const HeroCtaContainer = styled.div`
    white-space: nowrap;
    ${OnlyDesktop} {
        position: absolute;
        left: 50%;
        top: calc(100% + 16px);
        transform: translateX(-50%);
    }
`;
export const OuterDecor = styled.svg`
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
`;
export const Hero = styled.div`
    border: 1px solid #FFD363;
    box-shadow: 0px 0px 50px rgba(255, 212, 101, 0.27);
    width: 100%;
    max-width: clamp(224px, ${224 * 2}px, ${224 * 3}px);
    img {
        image-rendering: pixelated;
        width: 100%;
        display: block;
    }
`;
export const Stats = styled.div`
    background: rgba(9, 11, 10, 0.8);
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 4em 0;
    gap: 4em 0;
    flex-wrap: wrap;
    border-top: 1px solid hsl(43, 100%, 70%);
    border-bottom: 1px solid hsl(43, 100%, 70%);
    > div {
        width: 26%;
    }
    ${OnlyMobile} {
        > div {
            width: 45%;
        }
    }
`;
export const HeroStats = styled.div`
    grid-column: 7 / 13;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2em;
    > div {
        display: flex;
        flex-direction: column;
        gap: 0.75em;
    }
    ${OnlyMobile} {
        grid-column: 1 / 5;
        padding-top: 2.5em;
    }
`;
export const HeroStatsLarge = styled.div`
    ${props => css`
        ${props.numbers && css`
            font-family: 'Cinzel Decorative', serif;
        `}
        ${props.faded ? css`
            background: linear-gradient(to top, hsl(43, 0%, 40%) 0%, hsl(43, 0%, 70%) 100%);
        ` : css`
            background: linear-gradient(to top, hsl(43, 100%, 40%) 0%, hsl(43, 100%, 70%) 100%);
        `}
    `}
    ${SmartFluid('font-size', 32, 48, 56)};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 700;
`;
