import React, {Component, useRef, useEffect, StyleHTMLAttributes } from 'react';
import {observer} from 'mobx-react';
import seedrandom from 'seedrandom';

interface MinBlockProps {
  config: {
    blocksPerEdge: Number, // default -> 5
    canvasID: String, // required
    color: {
        primary: String,
        secondary: String
    }, // default -> a pair of random colors
    padding: Number, // default -> 20
    spacing: Number, // default -> 0,
    seed: String,
  },
  style: StyleHTMLAttributes,
}


const MinBlock = observer((props:MinBlockProps) => {
  const {config} = props;
  // TODO: make light/dark colors? contrasting combo
  let colors = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#f1c40f', '#e67e22', '#e74c3c'];
  let options = {};

  let rng = seedrandom(config.seed);

  options.blocksPerEdge = Math.max(config.blocksPerEdge || 5, 3);
  options.padding = (typeof config.padding === 'number') ? config.padding : 20;
  options.spacing = (typeof config.spacing === 'number') ? config.spacing : 0;
  // get a random color pair
  function getRandomColorPair() {
    let colorPrimary = colors[Math.floor(rng() * colors.length)];
    let colorSecondary = colors[Math.floor(rng() * colors.length)];
    while(colorSecondary === colorPrimary) {
      colorSecondary = colors[Math.floor(rng() * colors.length)];
    }
    return { primary: colorPrimary, secondary: colorSecondary };
  }
  options.color = (typeof config.color === 'object') ? config.color : getRandomColorPair();

  const canvasRef = useRef();

  // render
  function render() {
    let canvas = canvasRef.current;
    let canvasWidth = canvas.width;
    let canvasHeight = canvas.height;
    let ctx = canvas.getContext('2d');

    let Height = canvasHeight - 2 * options.padding;
    let Width = canvasWidth - 2 * options.padding;
    let squareHeight = Height / options.blocksPerEdge;
    let squareWidth = Width / options.blocksPerEdge;
    // draws a single color square
    function drawSquare(color, matrixX, matrixY) {
      let x = options.padding + (matrixX * squareWidth);
      let y = options.padding + (matrixY * squareHeight);
      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.rect(x + options.spacing, y + options.spacing, squareWidth - options.spacing * 2, squareHeight - options.spacing * 2);
      ctx.fill();
      ctx.closePath();
    }

    // fills entire matrix a single color
    function fillMatrix(color) {
      for (let i = 0; i < options.blocksPerEdge; i++) {
        for (let j = 0; j < options.blocksPerEdge; j++) {
          drawSquare(color, j, i);
        }
      }
    }

    // get the location dependant on a number
    function getLocation(number) {
      let a = Math.floor(number / options.blocksPerEdge);
      let b = number % options.blocksPerEdge;
      return [a, b];
    }

    // get a random integer between min and max
    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(rng() * (max - min + 1)) + min;
    }
    // fill the canvas with blocks at random positions
    function randFill(color) {
      let numBlocksReplace = getRandomInt(options.blocksPerEdge * options.blocksPerEdge * 0.25, options.blocksPerEdge * options.blocksPerEdge * 0.75);
      let replacedBlocks = [];
      while(replacedBlocks.length < numBlocksReplace) {
        let rand = getRandomInt(0, options.blocksPerEdge * options.blocksPerEdge - 1);
        if (replacedBlocks.indexOf(rand) === -1) {
          let coordinate = getLocation(rand);
          drawSquare(color, coordinate[1], coordinate[0]);
          replacedBlocks.push(rand);
        }
      }
    }
    canvas.style.background = options.color.primary;
    fillMatrix(options.color.secondary);
    randFill(options.color.primary);
  }

  useEffect(() => {
    render();
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={props.style}
      className={props.className}
    />
  );
});

export default MinBlock;
