import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { OnlyDesktop } from '../utils';

export const AccordionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  overflow: hidden;
  height: ${props => props.height}px;
  ${props => props.transition
    && css`
      transition: height 0.6s ease;
    `}
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
export const AccordionItem = styled.div`
  background: hsl(150, 10%, 10%);
`;
export const AccordionItemHead = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5em;
  gap: 0.5em;
  &:hover {
    cursor: pointer;
  }
`;
export const AccordionItemTitle = styled.div`
  font-size: 1.2em;
  color: hsl(43, 100%, 70%);
`;
export const AccordionItemTrigger = styled.div`
  width: 34px;
  height: 34px;
  margin-left: auto;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  &:hover {
    cursor: pointer;
  }
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    ${props => (props.open
    ? css`
            transform: translate(-50%, -10%) rotate(225deg);
          `
    : css`
            transform: translate(-50%, -60%) rotate(45deg);
          `)}
    transition: transform 0.4s cubic-bezier(0.84, -0.52, 0.21, 1.36);
    width: 8px;
    height: 8px;
    border-right: 2px solid hsl(43, 100%, 40%);
    border-bottom: 2px solid hsl(43, 100%, 40%);
  }
`;
export const AccordionItemDescription = styled.div`
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  max-height: ${props => (props.open ? `${props.contentHeight}px` : 0)};
  transition: max-height 0.6s ease;
  > div {
    padding: 0 1.5em 4em 1.5em;
    ${OnlyDesktop} {
      max-width: 86%;
    }
  }
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
`;
export const LoadMore = styled.div`
  color: rgba(255, 255, 255, 0.5);
  margin-top: 57px;
  position: relative;
  display: inline-block;
  a {
    display: inline-block;
    height: 1.5em;
  }
  &:after {
    width: 8px;
    height: 8px;
    border-right: 1.5px solid;
    border-bottom: 1.5px solid;
    content: '';
    display: inline-block;
    transform-origin: 100% 50%;
    transform: rotate(${props => (props.inverted ? '-135' : '45')}deg)
      translate(-50%, -60%);
    position: absolute;
    left: 110%;
    top: ${props => (props.inverted ? '0' : '66')}%;
  }
`;
