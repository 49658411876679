import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { OnlyMouse, SmartFluid } from '../utils';

export const OuterDecor = styled.svg`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  &:first-of-type {
    bottom: 100%;
  }
  &:last-of-type {
    top: 100%;
    transform: translateX(-50%) rotate(180deg);
  }
`;

export const InnerCta = styled.span`
  position: relative;
`;

export const HoverSpace = styled.span`
  display: block;
  position: absolute;
  left: -30px;
  top: -30px;
  right: -30px;
  bottom: -30px;
  background: transparent;
`;

export const Corners = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  &::before,&:after {
    display: block;
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: hsl(43, 100%, 69%);
  }
  &:first-of-type {
    &:before {
      left: -6px;
      top: -6px;
    }
    &:after {
      right: -6px;
      top: -6px;
    }
  }
  &:last-of-type {
    &:before {
      left: -6px;
      bottom: -6px;
    }
    &:after {
      right: -6px;
      bottom: -6px;
    }
  }
`;

export const Cta = styled.button`
  ${props => (props.size === 'large' ? 'font-size: 24px;' : 'font-size: 16px;')};
  position: relative;
  letter-spacing: 0.1em;
  border: 0;
  font-weight: 600;
  text-transform: uppercase;
  outline: none;
  color: white;
  transition: background 0.2s ease, color 0.2s ease, box-shadow 0.2s ease, filter 1s ease;
  text-decoration: none;
  display: flex;
  gap: 0.45em;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2em;
  font-family: 'Cinzel', serif;
  ${OnlyMouse} {
    &:hover,
    &:active {
      cursor: pointer;
      text-decoration: none;
    }
  }
  font-weight: 700;
  &:before,&:after {
    display: block;
    content: '';
    position: absolute;
    pointer-events: none;
  }
  ${props => (props.mode === 'secondary' ? css`
    filter: drop-shadow(0px 0px 30px rgba(255, 212, 101, 0.27));
    padding: 0;
    outline: 0;
    background: transparent;
    line-height: 20px;
    ${InnerCta} {
      padding: 20px 20px 18px 20px;
      line-height: 20px;
      background: hsla(150, 10%, 4%, 1);
      border: 1px solid hsl(43, 100%, 69%);
      color: hsl(43, 100%, 69%);
      min-width: 220px;
      mask:
        radial-gradient(circle 6px at top left ,#0000 90%,#000) top left,
        radial-gradient(circle 6px at top right,#0000 90%,#000) top right,
        radial-gradient(circle 6px at bottom left ,#0000 90%,#000) bottom left,
        radial-gradient(circle 6px at bottom right,#0000 90%,#000) bottom right;
      mask-size: 50% 50%;
      mask-repeat: no-repeat;
    }
    &:before,&:after {
      width: 8px;
      height: 8px;
      background: #090B0A;
      border: 1px solid #E9B738;
      transform: rotate(45deg);
      z-index: 1;
    }
    &:before {
      left: -3px;
    }
    &:after {
      right: -3px;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
      filter: grayscale(100%);
    }
  ` : css`
    color: ${props.danger ? '#FF180C' : '#FFBE16'};
    padding: 0.95em 0 0.85em 0;
    background: transparent;
    &:before,&:after {
      width: 100%;
      left: 0;
      height: 1px;
      transition: transform 0.6s cubic-bezier(1,-1.5,0,2.5);
    }
    &:before {
      background: ${props.danger ? '#FF180C' : '#FFCF51'};
      top: 0;
    }
    &:after {
      background: ${props.danger ? '#FF180C' : '#FFBF1A'};
      bottom: 0;
    }
    ${OnlyMouse} {
      &:not([disabled]):hover {
        &:before {
          transform: translateY(0.4em);
        }
        &:after {
          transform: translateY(-0.4em);
        }
      }
    }    
    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
      filter: grayscale(100%) blur(2px);
    }
  `)}
`;
