import { createGlobalStyle } from 'styled-components';
import emotionNormalize from 'emotion-normalize';
import { SmartFluid } from './components/vendor/utils';

const GlobalCSS = createGlobalStyle`
html,
    body {
        font-family: 'Cinzel', serif;
        background: #090B0A;
        color: white;
        padding: 0;
        margin: 0;
        font-weight: 500;
        scrollbar-width: none;
        -ms-overflow-style: none;
        font-display: block;
    }
    body {
        width: 100%;
        min-height: 100%;
        position: relative;
    }
    h1,
    .h1 {
        ${SmartFluid('font-size', 32, 48, 56)};
        margin: 0;
        font-weight: 700;
    }
    h2,
    .h2 {
        ${SmartFluid('font-size', 20, 30, 36)};
        margin: 0;
        font-family: 'Cinzel', serif;
        font-weight: 600;
    }
    h3,
    .h3 {
        ${SmartFluid('font-size', 15, 18, 24)};
        margin: 0;
        font-family: 'Cinzel', serif;
        font-weight: 600;
    }
    em {
        font-style: normal;
        font-family: 'Cinzel Decorative', serif;
    }
    @media (max-aspect-ratio: 1/1) and (max-height: 674px) {
        h2 {
            font-size: 19px;
        }
        h3 {
            font-size: 14px;
        }
    }
    a:link,a:visited {
        text-decoration: none;
        color: inherit;
        transition: color 0.2s ease;
    }
    p {
        line-height: 1.75em;
    }
    .img-fluid {
        max-width: 100%;
    }
    * {
        -webkit-tap-highlight-color: transparent;
    }
    *,*:before,*:after{
        -webkit-font-smoothing: antialiased;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        text-rendering: optimizeLegibility;
    }
    @font-face {
        font-family: "Cinzel";
        src: url("/assets/cinzel.woff2") format("woff2-variations");
        font-weight: 400 800;
    }
    @font-face {
        font-family: "Cinzel Decorative";
        src: url("/assets/cinzeldecorative.woff2") format("woff2-variations");
        font-weight: 400 800;
    }
    @font-face {
        font-family: "Inter";
        src: url("/assets/inter.woff2") format("woff2-variations");
        font-weight: 100 900;
    }

    input {
        background-color: rgba(9, 11, 10, 0.8);
        border-radius: 3px;
        border-width: 2px;
        border-color: #CC930080;
        box-shadow: none;
        border-style:solid;
        transition: all 0.3s;
        color: white;
        font-size: 20px;
        padding: 6px 12px;
        font-family: 'Cinzel', serif;
      }
      
      input:hover {
        border-color: #CC9300;
      }
      
      input:focus {
        outline: none;
        border-color: #CC9300;
      }
      
      input:active {
        border-color: #CC9300;
      }
      
      input:focus-visible {
        border-color: #CC9300;
      }
`;

export default GlobalCSS;
