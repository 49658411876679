import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './FooterStyled';

export const Footer: React.FC<{ }> = () => {
  return (
    <S.Footer>
      <a href="https://twitter.com/olivexai" target="_blank" title="Follow us on Twitter" rel="noopener noreferrer nofollow">
        <img src="/assets/logo/twitter.svg" width="36" height="36" alt="Twitter" />
      </a>
      <a href="https://www.olivex.ai/" target="_blank" title="Learn more about OliveX" rel="noopener noreferrer nofollow">
        <img src="/assets/logo/olivex.svg" width="109" height="36" alt="OliveX" />
      </a>
      <a href="https://keungz.com/" target="_blank" title="Keungz" rel="noopener noreferrer nofollow">
        <img src="/assets/logo/keungz.png" width={90 * 2} height={36 * 2} alt="Keungz" />
      </a>
      <a href="https://www.animocabrands.com/" target="_blank" title="Animoca Brands" rel="noopener noreferrer nofollow">
        <img src="/assets/logo/animoca.png" width={64 * 2} height={36 * 2} alt="Animoca" />
      </a>
      <a href="https://twitter.com/harry_forj/status/1602241281505345536" target="_blank" title="Yuga Labs IP Ecosystem" rel="noopener noreferrer nofollow">
        <img src="/assets/logo/yuga.png" width={135 * 2} height={36 * 2} alt="Yuga Labs" />
      </a>
      <S.Legal>
        © 2022, OliveX Holdings Limited. ACN 631 675 986. © 2022
        <Link to="/terms">Terms of use</Link>
        <Link to="/privacy">Privacy policy</Link>
        <a href="/NFTLicenceAgreement.pdf">Licensing Agreement</a>
      </S.Legal>
    </S.Footer>
  );
};
