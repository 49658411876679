/* eslint-disable prefer-template */
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import * as S from './LetterStoryEffectStyled';

export const LetterStoryEffect = (
  props: React.PropsWithChildren<{
    text: string;
    align?: 'center' | 'left' | 'right';
    alignMobile?: 'center' | 'left' | 'right';
    delay?: number;
    confirm?: boolean;
    confirmStatus?: boolean;
    repeating?: boolean;
  }>,
) => {
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, []);
  const words = props.text.trim().split(/\s+/) || [];
  let lettersCounter: number[] = [];
  for (let i = 0; i < words.length; i++) {
    lettersCounter.push(
      i > 0 ? lettersCounter[i - 1] + words[i].length : words[i].length,
    );
  }
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.2,
    triggerOnce: !props.repeating,
  });
  return (
    <S.LetterEffect
      ref={inViewRef}
      inview={inView && (props.confirm ? props.confirmStatus : true)}
      align={props.align}
      alignMobile={props.alignMobile}
    >
      {rendered && words?.map((word, wi) => (
        <S.Word key={word + wi}>
          {word.split('').map((letter, li) => (
            <React.Fragment key={letter + li}>
              <S.Letter
                special={letter !== letter.toLowerCase()}
                style={{
                  transitionDelay: ((props.delay ? props.delay : 0) + (Math.random() * 10 / 10) + (lettersCounter[wi] + li) * 0.03).toFixed(3) + 's',
                  transform: 'translate(' + Math.floor(Math.random() * 4 - 2) + 'em,' + Math.floor(Math.random() * 4 - 2) + 'em)',
                }}
              >
                {letter}
              </S.Letter>
              {words.length !== wi && word.length - 1 === li && (
                <S.Letter space>{' '}</S.Letter>
              )}
            </React.Fragment>
          ))}
        </S.Word>
      ))}
    </S.LetterEffect>
  );
};
