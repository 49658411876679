import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { LayoutGroup } from 'framer-motion/dist/framer-motion';

import { getName } from '../utils/utils';
import { store } from '../mobx/store';
import { Flex } from './Flex';
import { BidderItem } from './BidderItem';

interface RecentBiddersListProps {
}

const RecentBiddersList = observer((props:RecentBiddersListProps) => {
  const isShow = store.auctionState >= 1 && store.stats?.latestBidders?.length > 0;
  if (!isShow) return null;
  return (
    <React.Fragment>
      <Flex
        style={{
          marginTop: 20,
          gap: 30,
          flexWrap: 'wrap',
          // justifyContent: 'center',
          pointerEvents: 'all',
        }}
      >
        <LayoutGroup
          initial={false}
          key="recent-bidder-list"
        >
          {
            store.stats.latestBidders.map((b, idx) => {
              return (
                <BidderItem
                  key={`recent_bidders_list_${b.address}`}
                  href={`https://opensea.io/${b.address}`}
                  name={getName(b)}
                  img={b.account && b.account.profile_img_url}
                  addr={b.address}
                  layoutId={idx}
                />
              );
            })
          }
        </LayoutGroup>
      </Flex>
      <Flex
        style={{marginTop: 20}}
      >
        {getName(store.stats.latestBidders[0])}
        {' '}
        and
        {' '}
        {store.stats && Math.max(store.stats.participants - 1, 1)}
        {' '}
        others placed
        {' '}
        {(store.stats && Math.max(store.stats.biddedSlotsCount, 1)) || 1}
        {' '}
        bids
      </Flex>
    </React.Fragment>
  );
});

export default RecentBiddersList;
