import moment from 'moment';

import styled, { keyframes } from 'styled-components';


export const NetworkContextName = 'NETWORK';

export const NETWORK_ID = parseInt(process.env.REACT_APP_NETWORK, 10) || '80001';

export const CONNECTED_BEFORE_KEY = 'amsdfbawmfeawefaeagewg';

export const MS = 1;
export const S_MS = MS * 1000;
export const MINUTE_MS = S_MS * 60;
export const HOUR_MS = MINUTE_MS * 60;
export const DAY_MS = HOUR_MS * 24;

// const BASE_URL = ;
// const BASE_URL = 'http://localhost:31381';

const AUCTION_START_TS = 1676379600000;

export const NETWORK_CONFIGS = {
  1: {
    chinaID: 1,
    rpc: 'https://rpc.ankr.com/eth',
    id: '0x1',
    chainName: 'ETH Mainnet',
    auctionAddress: '0xEBA462AA6B835Ef0F4a85F9b0A2BEb3B1CE6bfE7',
    waitlistAddress: '0x63DEd28Cc3B5B415567577118166781F9909DDAf',
    AUCTION_START_TS,
    AUCTION_END_TS: AUCTION_START_TS + 72 * HOUR_MS,
    MINT_START_TS: AUCTION_START_TS + 72 * HOUR_MS,
    MINT_END_TS: AUCTION_START_TS + (72 + 48) * HOUR_MS,
    AIRDROP_START_TS: AUCTION_START_TS + (72 + 48) * HOUR_MS,
    API_END_POINT: 'https://auction-api.olivexgenesis.io',
  },
  80001: {
    rpc: 'https://rpc.ankr.com/polygon_mumbai',
    id: '0x13881',
    chainName: 'Mumbai Test Network',
    chainData: {
      chainId: '0x13881',
      chainName: 'Mumbai',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [
        'https://matic-mumbai.chainstacklabs.com',
        'https://rpc-mumbai.maticvigil.com',
        'https://matic-testnet-archive-rpc.bwarelabs.com',
      ],
      blockExplorerUrls: [
        'https://mumbai.polygonscan.com',
      ],
    },
    auctionAddress: '0x476Ad39080317083Ad5de12E423fBFE340025bAB',
    waitlistAddress: '0x8Fd50Fa93FD666d5E7Fde8E7c86f1804Bb8aA19F',
    AUCTION_START_TS: Date.now() + MINUTE_MS * 1,
    AUCTION_END_TS: Date.now() + MINUTE_MS * 3,
    MINT_START_TS: Date.now() + MINUTE_MS * 4,
    MINT_END_TS: Date.now() + MINUTE_MS * 6,
    AIRDROP_START_TS: Date.now() + MINUTE_MS * 8,
    API_END_POINT: 'https://olxg-testnet.keungz.com',
  },
};

type ConfigType = typeof NETWORK_CONFIGS[1];

export const WIDTH_SHORTEN_THRESHOLD = 1024;
export const WIDTH_THRESHOLD = 768;
export const MAX_DESKTOP_WIDTH = 1100;

export const NETWORK_CONFIG:ConfigType = NETWORK_CONFIGS[NETWORK_ID];

export const COLORS = {
  grey1: '#3A4142',
  grey2: '#2E3435',
  grey3: '#1A1E1F',
  dark: '#0E0C34',
  purple1: '#D585FF',
  purple2: '#BF77E6',
  purple3: '#9F63BF',
  skyblue: '#64CCF7',
  blue1: '#a4d9f6',
  blue2: '#80c9f2',
  blue3: '#69b7e3',
  green1: '#8BFCE5',
  green2: '#7DE3CF',
  green3: '#68BDAC',
  pink: '#FCC5B1',
  orange: '#FCD4A4',
  alert: '#DE3146',
  darkBlack: '#050505',

  main: 'white',
  highlight: 'rgb(215, 240, 211)',
  highlight2: 'rgb(239, 182, 21)',
  secondary: '#b5b8fc',
};

export const FONT = {
  CaliforniaGothic: 'CaliforniaGothic',
  Openhuninn: 'Openhuninn',
  Minercraftory: 'Minercraftory',
};

export const getLtMediaQuery = size => `@media only screen and (max-width: ${size}px)`;


export const XS_WIDTH = 600;
export const SM_WIDTH = 960;
export const MD_WIDTH = 1280;
export const LG_WIDTH = 1920;

export const MediaQueryMobileOnly = getLtMediaQuery(WIDTH_THRESHOLD);
export const MediaQueryShortenOnly = getLtMediaQuery(WIDTH_SHORTEN_THRESHOLD + 1);

export const MediaQueryXSOnly = getLtMediaQuery(XS_WIDTH);
export const MediaQuerySMOnly = getLtMediaQuery(SM_WIDTH);
export const MediaQueryMDOnly = getLtMediaQuery(MD_WIDTH);
export const MediaQueryLGOnly = getLtMediaQuery(LG_WIDTH);


export const STRING = {
  // presaleNotStartedAlert: `Presale has not started yet. Presale will be started at ${presaleMoment.format('Do MMM hh:mma UTC+z')}.`,
  // publicSaleNotStartedAlert: `Public sale has not started yet. Public sale will be started at ${publicSaleMoment.format('Do MMM hh:mma UTC+z')}.`,
  // selectMintCount: 'Please select count of you want to mint',
  // verifyWhitelistMessage: 'This will confirm and verify if you can mint during presale.',
  // insufficientFunds: 'Not enough ETH to mint',
  // moreThanMaxMint: 'You would like to mint {0} , but only {1} is available for you to mint now.',
  // confirmTx: 'Please confirm the transaction at your wallet',
  // txSubmitted: 'Transaction submitted, waiting for confirmation',
  // presaleNotStartedAlert: `Presale has not started yet. Please come back at ${presaleMoment.format('hh:mma UTC+z on Do MMM')}.`,
};

export const PHASE = {
  BEFORE_ANYTHING: 'BEFORE_ANYTHING',
  PRESALE: 'PRESALE',
  INTER_PHASE: 'INTER_PHASE',
  PUBLIC: 'PUBLIC',
};

export const ROTATE_KEYFRAMES = keyframes`
0% { transform: rotate(0deg); }
50% { transform: rotate(180deg);  }
100% { transform: rotate(360deg);  }
`;

export const CONTAINER_MAX_WIDTH = 1200;

export const BTN_CLASS = 'submit_button w-button simple-btn';

export const CONFIRM_TX = 'Please confirm your transaction with metamask.';
export const PROCESSING_TX = 'Your Transaction is processing...';

export const DATE_FORMAT = 'DD-MM-YYYY';

export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const SECTION_MAX_WIDTH = 800;
export const BIDDER_ITEM_WIDTH = (SECTION_MAX_WIDTH - (30 * 4)) / 5;

export const DISCORD_URL = '';

export const IG_URL = '';

export const OS_URL = '';

export const SIGNATURE_MSG = 'Welcome to Welcome to OliveX Genesis.\nClick "Sign" to continue.';

export const MIN_BID = 0.1;
