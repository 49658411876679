import React, {Component, useState} from 'react';
import {observer} from 'mobx-react';
import { Flex } from 'rebass';
import { REFUND_AUCTION } from './results';
import { store } from '../mobx/store';
import { BTN_CLASS, CONFIRM_TX, PROCESSING_TX } from '../utils/constants';
import { VFlex } from './Flex';
import { Cta } from './vendor/Cta/Cta';

interface RefundAuctionPartProps {
}

const RefundAuctionPart = observer((props:RefundAuctionPartProps) => {
  const [isLoading, setIsLoading] = useState();
  const [message, setMessage] = useState();
  if (!REFUND_AUCTION[store.web3Ctx?.account?.toLowerCase()]) return null;
  return (
    <VFlex>
      <Flex style={{alignItems: 'center', gap: 12}}>
        <h3 style={{marginTop: 9}}>Refund Deposit:</h3>
        <Cta
          mode={!store.isUserRefunded && 'secondary'}
          size={!store.isUserRefunded && 'large'}
          onClick={async () => {
            if (store.isUserRefunded || isLoading) return;
            setIsLoading(true);
            try {
              await store.auctionContract.estimateGas.refund(REFUND_AUCTION[store.web3Ctx.account.toLowerCase()]);
              setMessage(CONFIRM_TX);
              const res = await store.auctionContract.refund(REFUND_AUCTION[store.web3Ctx.account.toLowerCase()]);
              setMessage(PROCESSING_TX);
              await res.wait();
              store.isUserRefunded = true;
              setMessage('Refund success!');
            } catch (error) {
              let m = store.getErrorMessageWithHeader(error);
              setMessage(`${m}`);
            }
            setIsLoading(false);
          }}
        >
          {(() => {
            if (isLoading) {
              return 'Loading...';
            }
            return store.isUserRefunded ? 'REFUNDED' : 'REFUND';
          })()}
        </Cta>
        {
          (store.isUserRefunded) && (
            <span>
              <img
                style={{transform: 'translateY(-1px)', marginLeft: 8}}
                src="/images/circle-tick.svg"
                alt=""
              />
            </span>
          )
        }
      </Flex>
      {
        message && (
          <div style={{color: message.startsWith('Error') && 'red'}}>
            {message}
          </div>
        )
      }
    </VFlex>
  );
});

export default RefundAuctionPart;
